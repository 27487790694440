import {Meta} from '@unhead/schema';
import { LENDI_LOGO_JPG, OG_TYPE } from '~/constants/pageConstants/pageMeta/CommonMeta';

type LHeadOptions = {
  title: string | ComputedRef<string>
  description: string | ComputedRef<string>
  canonicalRoutePath?: string
  robots?: 'index' | 'noindex'
}
export default function useLHead(options: LHeadOptions) {
  const basePath = useRuntimeConfig()?.appBaseUrl ?? '';
  const routePath = (useRoute())?.path ?? '';
  useHead({
    title: options.title,
    link: options.canonicalRoutePath ? [
      {
        rel: 'canonical',
        href: basePath + (options.canonicalRoutePath || routePath),
      },
    ] : undefined,
  });
  useSeoMeta({
    title: options.title,
    description: options.description,
    ogImage: LENDI_LOGO_JPG,
    twitterImage: LENDI_LOGO_JPG,
    ogDescription: options.description,
    ogTitle: options.title,
    ogType: OG_TYPE,
    robots: options.robots,
  });
}
